.form {
    display: flex;
    gap: 10px;
}

.form--row {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 610px;
}

.form--column {
    flex-direction: column;
}


.input__container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.input__container p span {
    color: var(--danger);
}

.form__input {
    background-color: white;
    height: 40px;
    width: 300px;
    padding-inline: 10px;
    border-radius: 5px;
}

.input__container--disabled .form__input {
    background-color: gray;
}

.form__input--check {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form__input--check:hover, .form__check:hover {
    cursor: pointer;
}

.form__check {
    width: 20px;
    height: 20px;
    border: 1px solid rgba(0,0,0,0.4);
    border-radius: 3px;
}

.form__checked{
    width: 100%;
    height: 100%;
    color: var(--primary);
}


.form input:focus {
    outline-color: var(--primary);
}

.form p {
    position: relative;
    inset-inline-start: 15px;
    color: white;
}