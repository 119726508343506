.tableContainer {
    overflow: scroll;
    scrollbar-width: none;
}

.tableContainer::-webkit-scrollbar {
    display: none;
}

.table {
    width: 100%;
    min-width: 800px;
    padding-inline: 20px;
}


.table__header {
    color: white;
    background-color: var(--primary);
}

.table__header th {
    text-align: center;
    padding-block: 10px;
}

.table__header th:first-child {
    padding-inline-start: 10px;
    border-top-left-radius: 10px;
}

.table__header th:last-child {
    border-top-right-radius: 10px;
}

.table__body {
    background-color: white;
}

.table__body tr {
    font-size: 14px;
    border-block-end: rgba(0,0,0,0.2) solid 1px;
}

.table__body tr:hover {
    background-color: #c7ded5;
    color: var(--primary);
    cursor: pointer;
    border: none;
}

.table__body tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}

.table__body tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}

.table__body td {
    padding-block: 10px;
}

.table__body td:not(:nth-child(2)) {
    text-align: center;
}

.table--form td{
    text-align: center;
}

.table:not(.table--form) .table__body td:nth-child(2) {
    box-sizing: border-box;
    padding-inline-start: 40px;
}

.table--form {
    font-size: 14px;
}

.table--form .table__header th {
    padding-block: 5px;
}

.table__delete {
    visibility: hidden;
    margin-block-start: 6px;
    width: 25px;
    height: 25px;
}

.table__delete * {
    color: #c94747;
    width: 100%;
    height: 100%;
}

.table__body tr:hover .table__delete{
    visibility: visible;
}