.searchBar {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 2;
}

.searchBar--main {
    padding: 10px 20px;
    min-width: 80%;
    border-radius: 10px;
}

.searchBar--main input {
  height: 25px;
}

.searchBar--form {
  padding: 5px 10px;
  border-radius: 7px;
}

.searchBar--form input {
    font-size: 14px;
  height: 20px;
}

.searchBar input {
  width: 100%;
}

.searchBar input:focus {
  outline: none;
}

.searchBar__svg {
  stroke: var(--primary);
}

@media screen and (max-width: 1024px) {
  .searchBar {
    width: 100%;
  }
}
