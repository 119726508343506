.headerButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-inline: 10px;
  box-sizing: border-box;
  min-width: 100px;
  height: 45px;
  font-weight: 600;
  color: var(--primary);
  background-color: #fff;
  border-radius: 10px;
}

.headerButton * {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.headerButton:hover {
  background-color: var(--primary);
  color: #fff;
}

.headerButton div {
  position: absolute;
  inset-inline-end: 10px;
  height: 20px;
  width: 20px;
  background-color: var(--primary);
  border-radius: 2px;
  color: white;
  font-size: 12px;
}
