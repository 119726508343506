.stockContainer {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 0.1fr 2fr;
    padding: 20px 35px;
    background-color: #232c26;
    grid-gap: 10px;
  
  }
  @media screen and (max-width: 1024px) {
      .stockContainer {
          display: flex;
          flex-direction: column;
          padding: 20px 20px;
      }
  }
  
  .viewSection {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-height: 95vh;
  }