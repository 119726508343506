.form__drop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form__drop span {
    margin-inline-start: 3px;
}

.form__arrow {
    height: 30px;
    width: 20px;
}

.form__dropdown {
    display: flex;
    flex-direction: column;
    margin-block-start: 5px;
    gap: 10px;
    width: 300px;
}

.form__results {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 5px;
    max-height: 127px;
    overflow-y: scroll;
    scrollbar-width: none;
}

.form__results::-webkit-scrollbar {
    display: none;
}

.form__result {
    padding-block: 5px;
    display: flex;
    flex-wrap: wrap;
}

.form__result:hover {
    cursor: pointer;
    border-radius: inherit;
    background-color: var(--light);
}

.form__result p {
    font-size: 14px;
    color: black;
}

.form__empty {
    font-size: 14px;
}

.form__newItem {
    padding-block: 3px;
    color: var(--primary);
    background-color: white;
    font-weight: bold;
    border-radius: 5px;
    font-size: 14px;
}

.form__newItem:hover {
    background-color: var(--primary);
    color: white;
}

.form__identifier {
    display: none;
}