.error__icon {
    position: relative;
    color: var(--danger);
    height: 70px;
    width: 70px;
    margin-inline: auto;
}

.error__title {
    color: var(--danger);
}