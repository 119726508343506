@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

:root {
  --primary: #258D00;
  --secondary: #47CB00;
  --light: #C7DED5;
  --dark: #232C26;
  --danger: #ad4444;
  background-color: #232c26; 

}

* {
  font-family: 'Inter', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
