.headerSelect {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  box-sizing: border-box;
  min-width: 200px;
  max-width: 250px;
  height: 45px;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
}

.headerSelect select {
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-inline: 15px;
  font-weight: 600;
  color: var(--primary);
  background-color: #fff;
  cursor: pointer;
  border-radius: inherit;
}

.headerSelect select:active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.headerSelect select:focus {
  outline: none;
}

.headerSelect * {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.headerSelect:hover,
.headerSelect:hover * {
  background-color: var(--primary);
  color: #fff;
}

.headerSelect__arrow {
  position: absolute;
  width: 30px;
  height: 30px;
  margin-block: auto;
  inset-inline-end: 5px;
  color: var(--primary);
}

.headerSelect__arrow *{
  width: 100%;
  height: 100%;
}
