.modalContainer {
  position: fixed;
  display: grid;
  place-items: center;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 30;
}

.modalContainer::-webkit-scrollbar {
  display: none;
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  padding: 20px;
  background-color: var(--light);
  border-radius: 10px;
  gap: 10px;
}

.modal__body {
  background-color: var(--dark);
  padding: 15px;
  max-width: 100%;
  border-radius: 10px;
}

.modal__title {
  margin-block-end: 10px;
  font-size: 20px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.modal__title--small {
  max-width: 300px;
}

.modal__title--alt {
  text-align: start;
}

.modal__title--error {
  color: var(--danger);
}

.modal__text {
  color: white;
}

.modal__actions {
  width: 100%;
  display: flex;
  justify-content: stretch;
  gap: 10px;
}

.modal__actions button {
  height: 30px;
  flex-grow: 2;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  color: white;
  background-color: var(--primary);
}

.modal__actions button:hover {
  background-color: var(--dark);
}

.modal__close {
  display: grid;
  place-items: center;
  width: 25px;
  height: 25px;
  box-sizing: content-box;
  padding: 0px 10px;
}

.modal__close > * {
  width: 100%;
  height: 100%;
  color: white;
}

.modal__accum span {
  font-weight: bold;
}

.modal__table {
  margin-block-start: 15px;
}

.modal__empty {
  font-size: 14px;
  padding-block: 5px;
  color: black;
  background-color: white;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 800px;
  padding-inline: 15px;
}

.modal__accum {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
}

.modal__link {
  color: var(--primary);
  text-decoration: underline;
}

.modal__link:hover {
  color: var(--light);
}

.modal__btn {
  display: flex;
  width: 100%;
  padding-block: 5px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  color: var(--primary);
  background-color: white;
}

.modal__btn--middle {
    margin-block-start: 15px;
}

.modal__btn:hover {
    color: white;
    background-color: var(--primary);
}

.modal__initAction {

  padding: 4px 8px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  color: var(--primary);
  background-color: white;
}

.modal__initAction:hover {
  color: white;
  background-color: var(--primary);
}

.modal__accum--only {
  text-align: left;
  width: 50%;
}